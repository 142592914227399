import { CardHeader, TextField } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../../../setting/AuthProvider';
import Avatar from '../../Avatar';

const inputStyle = { background: "#909090", color: "white" };

type PostFormHeaderProps = {
	title: string,
	setTitle: React.Dispatch<React.SetStateAction<string>>
}

export default function PostFormHeader({ title, setTitle }: PostFormHeaderProps) {
	const { user } = useContext(UserContext);
	return (
		<>
			{user && <CardHeader
				avatar={
					<Avatar
						link={'/profile/' + user.id}
						username={user.username}
					/>
				}
				title={<TextField
					sx={{ fontSize: "1rem" }}
					placeholder='Titre du post'
					inputProps={{ style: inputStyle }}
					value={title}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
				/>}
			/>}
		</>
	)
}