import { CommentType } from '../../type/CommentType';

export const addComment = async (content: string, postId: string): Promise<CommentType> => {
	const user = JSON.parse(localStorage.getItem('userInfo')!);
	return await fetch(process.env.REACT_APP_SERVER_URL + '/comments', {
		method: 'POST',
		mode: 'cors',
		headers: {
			Authorization: `Bearer ${user?.token}`,
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		credentials: 'same-origin',
		body: JSON.stringify({
			postId: postId,
			content: content,
		})
	})
	.then(response => response.json())
	.catch(response => console.error(response));
}

export const deleteComment = async (commentId: string) => {
	const user = JSON.parse(localStorage.getItem('userInfo')!);
	return await fetch(process.env.REACT_APP_SERVER_URL + '/comments/' + commentId, {
		method: 'DELETE',
		mode: 'cors',
		headers: {
			Authorization: `Bearer ${user?.token}`,
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		credentials: 'same-origin'
	})
		.then(response => response.json())
		.catch(response => console.error(response));
}