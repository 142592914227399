import { ChangeEvent, useState } from "react";
import { RegistrationSchema } from '../../action/registration.action';
import { registration } from '../../api/registration/POST';
import ButtonForm from '../../component/form/ButtonForm';
import ErrorMessage from '../../component/form/ErrorMessage';
import FooterLinks from '../../component/form/FooterLinks';
import Form from '../../component/form/Form';
import FormBody from '../../component/form/FormBody';
import FormTitle from '../../component/form/FormTitle';
import Input from '../../component/form/Input';
import SuccessRegistrationModal from '../../component/SuccessRegistrationModal';
import { ApiResponseType } from '../../type/ApiResponseType';
import { ErrorType } from '../../type/ErrorType';
import './registration.css';

export default function RegistrationPage() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errors, setErrors] = useState<ErrorType[]>([]);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const links = [{ label: "J'ai déjà un compte", href: '/login' }];
	const isInputInError = (pathName: string) => {
		return !!errors.find((error) => error.path === pathName)
	}

	const handleSubmit = async (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const formData = RegistrationSchema.safeParse({
			login: username,
			password: password,
			confirmPassword: confirmPassword
		});

		if (!formData.success) {
			const errors = formData.error.issues.map(issue => ({ message: issue.message, path: issue.path[0] }));
			setErrors(errors);
		} else {
			const response: ApiResponseType = await registration({ login: formData.data.login, password: formData.data.password });
			if (response.ok) {
				setIsModalOpen(true);
			} else {
				setErrors([{ message: response.body, path: "login" }])
			}
		}
	}

	return (
		<>
			<SuccessRegistrationModal open={isModalOpen} />
			<Form handleSubmit={handleSubmit}>
				<FormTitle label='Inscription' />
				<FormBody>
					<Input
						label="Nom d'utilisateur"
						type="text"
						value={username}
						setValue={setUsername}
						isInputInError={isInputInError("login")} />
					<Input
						label="Mot de passe"
						type='password'
						value={password}
						setValue={setPassword}
						isInputInError={isInputInError("password")} />
					<Input
						label="Confirmer le mot de passe"
						type='password'
						value={confirmPassword}
						setValue={setConfirmPassword}
						isInputInError={isInputInError("confirmPassword")} />
				</FormBody>
				<ErrorMessage errors={errors} />
				<FooterLinks links={links} />
				<ButtonForm label="S'inscrire" setErrors={setErrors} />
			</Form>
		</>
	)
}