import { PostType } from '../../type/PostType';

export const getPosts = async (): Promise<PostType[]> => {
	const user = JSON.parse(localStorage.getItem('userInfo')!);
	return await fetch(process.env.REACT_APP_SERVER_URL + '/posts', {
		method: 'GET',
		headers: { Authorization: `Bearer ${user?.token}` },
		mode: 'cors'
	})
		.then(response => response.json())
		.catch(response => console.error(response));
}

export const getPostById = async (id: string) => {
	const user = JSON.parse(localStorage.getItem('userInfo')!);
	return await fetch(process.env.REACT_APP_SERVER_URL + '/posts/' + id, {
		method: 'GET',
		headers: { Authorization: `Bearer ${user?.token}` },
		mode: 'cors'
	})
		.then(response => response.json())
		.catch(response => console.error(response));
}

export const getPostsByUser = async (id: string) => {
	const user = JSON.parse(localStorage.getItem('userInfo')!);
	return await fetch(process.env.REACT_APP_SERVER_URL + `/posts?` +
		new URLSearchParams({ userId: id }).toString(), {
		method: 'GET',
		headers: { Authorization: `Bearer ${user?.token}` },
		mode: 'cors'
	})
		.then(response => response.json())
		.catch(response => console.error(response));
}