export const login = async (LoginFormData: { data: { login: string, password: string }; }) => {
	let headers = new Headers();
	headers.set(
		'Authorization',
		'Basic ' +
		btoa(LoginFormData.data.login + ':' + LoginFormData.data.password)
	);

	return await fetch(process.env.REACT_APP_SERVER_URL + '/login', {
		method: 'POST',
		mode: 'cors',
		headers: headers,
		credentials: 'same-origin'
	})
		.then(response => response.text())
		.catch(response => console.error(response));
}

