import { Box, Link, Modal, Typography } from '@mui/material';

type SuccessRegistrationModalProps = {
	open: boolean
}

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	borderRadius: 1,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 7,
	color: 'black',
};

export default function SuccessRegistrationModal({ open }: SuccessRegistrationModalProps) {
	return (
		<>
			<Modal
				open={open}
			>
				<Box sx={style}>
					<Typography>Compte créé avec succès ! Veuillez vous <Link href="/login">authentifier</Link>.</Typography>
				</Box>
			</Modal>
		</>
	)
}