import { CardHeader, Typography } from '@mui/material';
import { CommentType } from '../../type/CommentType';
import { DatetimeToString } from '../../utils/DateToString';
import Avatar from '../Avatar';

type CommentHeaderPropsType = {
	comment: CommentType
}

export default function CommentHeader({ comment }: CommentHeaderPropsType) {
	return (
		<CardHeader
			avatar={
				<Avatar link={'/profile/' + comment.author.id} username={comment.author.username} />
			}
			subheader={
				<Typography sx={{color: "white", fontSize: "0.9rem"}}>
					Posté le {DatetimeToString(comment.createdAt)}
				</Typography>}
			sx={{ textAlign: "left" }}
		/>
	)
}