import { jwtDecode } from 'jwt-decode';
import { useContext, useState } from 'react';
import { LoginSchema } from '../../action/login.action';
import { login } from '../../api/login/POST';
import ButtonForm from '../../component/form/ButtonForm';
import ErrorMessage from '../../component/form/ErrorMessage';
import FooterLinks from '../../component/form/FooterLinks';
import Form from '../../component/form/Form';
import FormBody from '../../component/form/FormBody';
import FormTitle from '../../component/form/FormTitle';
import Input from '../../component/form/Input';
import { UserContext } from '../../setting/AuthProvider';
import { ErrorType } from '../../type/ErrorType';
import './login.css';

type JwtPayloadType = {
	id: string,
	iss: string;
	sub: string;
	roles: { authority: string }[];
	exp: number;
	iat: number;
};

export default function LoginPage() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState<ErrorType[]>([]);
	const { setUser } = useContext(UserContext);
	const links = [
		{ label: 'Mot de passe oublié ?', href: '#' },
		{ label: "S'inscrire", href: '/registration' }
	]
	const isInputInError = (pathName: string) => {
		return !!errors.find((error) => error.path === pathName)
	}

	const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const formData = LoginSchema.safeParse({
			login: username,
			password: password
		});

		if (!formData.success) {
			const errors = formData.error.issues.map(issue => ({ message: issue.message, path: issue.path[0] }));
			setErrors(errors);
		} else {
			const token = await login(formData);
			if (token) {
				const tokenInfos: JwtPayloadType = jwtDecode(token);
				console.log(tokenInfos);
				setUser!({
					id: tokenInfos.id,
					username: tokenInfos.sub,
					role: tokenInfos.roles[0].authority,
					token: token
				});
			} else {
				setErrors([...errors, { message: "Erreur lors de l'authentification.", path: "undefined" }]);
			}
		}
	};

	return (
		<>
			<Form handleSubmit={handleSubmit}>
				<FormTitle label="Connexion" />
				<FormBody>
					<Input
						label="Nom d'utiisateur"
						type='text'
						value={username}
						setValue={setUsername}
						isInputInError={isInputInError("login")}
					/>
					<Input
						label='Mot de passe'
						type='password'
						value={password}
						setValue={setPassword}
						isInputInError={isInputInError("password")}
					/>
				</FormBody>
				<ErrorMessage errors={errors} />
				<FooterLinks links={links} />
				<ButtonForm label='Valider' setErrors={setErrors} />
			</Form>
		</>
	);
}
