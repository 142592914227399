import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../setting/AuthProvider';
import Avatar from './Avatar';


export default function ProfileMenu() {
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
	const settings = [
		{ name: 'Profile', onclick: () => { navigate('/profile/' + user?.id); handleCloseUserMenu() } },
		{ name: 'Déconnexion', onclick: () => { navigate('/logout'); handleCloseUserMenu() } }
	];

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	return (
		<>
			<Box sx={{ flexGrow: 0, margin: "0.5% 1% 0 0" }}>
				<Tooltip title="Open settings">
					<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
						<Avatar />
					</IconButton>
				</Tooltip>
				<Menu
					sx={{ mt: '45px' }}
					id="menu-appbar"
					anchorEl={anchorElUser}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={Boolean(anchorElUser)}
					onClose={handleCloseUserMenu}
				>
					{settings.map((setting) => (
						<MenuItem key={setting.name} onClick={setting.onclick}>
							<Typography textAlign="center">{setting.name}</Typography>
						</MenuItem>
					))}
				</Menu>
			</Box >
		</>
	)
}