import { TextField } from '@mui/material';

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px whitesmoke inset" }; //background doesn't turn yellow with autofill

type InputType = {
	label: string,
	type: string,
	value: string,
	setValue: (newValue: string) => void,
	isInputInError: boolean
}

export default function Input({ label, type, value, setValue, isInputInError }: InputType) {
	return (
		<>
			<TextField
				required
				value={value}
				type={type}
				label={label}
				error={isInputInError}
				variant='outlined'
				inputProps={{ style: inputStyle }}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
			/>
		</>
	)
}