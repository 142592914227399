import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../setting/AuthProvider";

export default function Logout() {
	const { setUser } = useContext(UserContext);
	useEffect(() => {
		setUser!({
			id: "",
			username: "",
			role: "",
			token: ""
		})
	}, [])
	return (
		<Navigate to="/login" replace={true} />
	)
}