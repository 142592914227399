import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';

export type User = {
	id: string;
	username: string;
	role: string;
	token: string;
};

interface UserContextType {
	user: User;
	setUser: Dispatch<SetStateAction<User>>;
}

export const UserContext = React.createContext<Partial<UserContextType>>({});

export const UserProvider: React.FC<{ children: ReactNode }> = ({
	children
}) => {
	const [user, setUser] = useState<User>(() => {
		const storedUser = localStorage.getItem('userInfo');
		return storedUser
			? JSON.parse(storedUser)
			: {
				id: "",
				username: "",
				role: "",
				token: ""
			};
	});

	const setLocalUser: Dispatch<SetStateAction<User>> = user => {
		setUser(user);
		localStorage.setItem('userInfo', JSON.stringify(user));
	};

	const contextValue: UserContextType = {
		user,
		setUser: setLocalUser
	};

	return (
		<UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
	);
};
