import { CardContent, Typography } from '@mui/material';
import { PostType } from '../../type/PostType';
import './post.css';

type PostContentProps = {
	post: PostType
}

export default function PostContent({ post }: PostContentProps) {
	return (
		<>
			<CardContent sx={{ padding: "0 3%" }}>
				<Typography
					sx={{ fontSize: "1rem", whiteSpace: "pre-line" }}
					align='justify'
				>
					{post.content}
				</Typography>
			</CardContent>
		</>
	)
}