import { CardContent, Typography } from '@mui/material';
import { PostType } from '../../type/PostType';
import './post.css';

type PostContentPreviewProps = {
	post: PostType
}

export default function PostContentPreview({ post }: PostContentPreviewProps) {
	return (
		<CardContent sx={{ padding: "0 3%" }}>
			<Typography color='white' sx={{ fontSize: "1rem" }}>
				{post.content.length > 300 ? post.content.slice(0, 300) + "..." : post.content}
			</Typography>
		</CardContent>
	)
}