import { CardContent, styled, Typography } from '@mui/material';
import { CommentType } from '../../type/CommentType';

const CardContentComment = styled(CardContent)(`
	padding: 0;
	&:last-child {
	  padding-bottom: 1;
	}
`);

type CommentContentPropsType = {
	comment: CommentType
}

export default function CommentContent({ comment }: CommentContentPropsType) {

	return (
		<CardContentComment sx={{ textAlign: "left", padding: "0 3%" }} >
			<Typography variant='body2' gutterBottom align='justify'>
				{comment.content}
			</Typography>
		</CardContentComment >
	)
}