import { Box, Link } from '@mui/material'

type FooterLinksProps = {
	links: LinkType[]
}

type LinkType = {
	label: string,
	href: string
}

export default function FooterLinks({ links }: FooterLinksProps) {
	return (
		<>
			<Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "3% 5%" }}>
				{links.length && links.map(link => (
					<Link
						key={link.label}
						href={link.href}
						underline='hover'
						color="steelblue"
					>
						{link.label}
					</Link>
				))}
			</Box>
		</>
	)
}