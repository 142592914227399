import { PostType } from '../../type/PostType';

export const getLikedPostsByUser = async (): Promise<PostType[]> => {
	const user = JSON.parse(localStorage.getItem('userInfo')!);
	return await fetch(process.env.REACT_APP_SERVER_URL + '/posts/liked', {
		method: 'GET',
		headers: { Authorization: `Bearer ${user?.token}` },
		mode: 'cors'
	})
		.then(response => response.json())
		.catch(response => console.error(response));
}