import { Box } from '@mui/material';

export default function FormBody({ children }: any) {
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: "space-between",
					margin: '0 5%'
				}}

			>
				{children}
			</Box>
		</>
	)
}