import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { getLikedPostsByUser } from '../../api/liked/GET';
import PostList from '../../component/post/PostList';
import { PostType } from '../../type/PostType';

export default function LikedPage() {
	const [posts, setPosts] = useState<PostType[]>([]);

	useEffect(() => {
		getLikedPostsByUser().then(setPosts);
	}, [])

	return <>
		<Box className='body_grid'>
			<h1 className='body_grid_title'>Posts aimés</h1>
			<Box>
				<PostList posts={posts} />
			</Box>
		</Box>
	</>
}