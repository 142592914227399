import { Box } from '@mui/material';

type FormProps = {
	children: any,
	handleSubmit: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function Form({ children, handleSubmit }: FormProps) {
	return (
		<>
			<Box
				component='form'
				onSubmit={(event: React.ChangeEvent<HTMLInputElement>) => handleSubmit(event)}
				sx={{
					backgroundColor: 'whitesmoke',
					padding: '2% 2% 3% 2%',
					margin: '15% 28%',
					width: '40%',
					borderRadius: '10px'
				}}
			>
				{children}
			</Box>
		</>
	)
}