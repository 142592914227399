import { Typography } from '@mui/material'
import { ErrorType } from '../../type/ErrorType'

type ErrorMessageProps = {
	errors: ErrorType[],
}

const style = { color: 'red', marginLeft: "5%", marginTop: "1%" };

export default function ErrorMessage({ errors }: ErrorMessageProps) {
	return (
		<>
			{errors.length > 0 && errors.map((error, index) => (
				<Typography key={error.path + "" + index} style={style}>{error.message}</Typography>
			))}
		</>
	)
}