import { CardHeader, Tooltip, Typography } from '@mui/material';
import { PostType } from '../../type/PostType';
import { DatetimeToString } from '../../utils/DateToString';
import Avatar from '../Avatar';

type PostHeaderProps = {
	post: PostType
}

export default function PostHeader({ post }: PostHeaderProps) {
	return (
		<>
			<CardHeader
				avatar={
						<Avatar link={'/profile/' + post.author.id} username={post.author.username} />
				}
				title={<Typography sx={{ fontSize: "1rem" }}>
					{post.title}
				</Typography>}
				subheader={
					<Typography sx={{ color: "white", fontSize: "0.8rem" }}>
						{DatetimeToString(post.createdAt)}
					</Typography>
				}
			/>
		</>
	)
}