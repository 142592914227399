import SendIcon from '@mui/icons-material/Send';
import { Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPostComments } from '../../api/comment/GET';
import { addComment } from '../../api/comment/POST';
import { getPostById } from '../../api/post/GET';
import CommentList from '../../component/comment/CommentList';
import Post from '../../component/post/Post';
import { CommentType } from '../../type/CommentType';
import { PostType } from '../../type/PostType';

export default function PostPage() {
	const { id } = useParams()
	const [comment, setComment] = useState<string>("");
	const [post, setPost] = useState<PostType>();
	const [comments, setComments] = useState<CommentType[]>([]);

	const keyPress = (e: React.KeyboardEvent) => {
		if (e.key === "Enter")
			handleSubmit();
	}

	useEffect(() => {
		if (id) {
			getPostById(id).then(setPost);
			getPostComments(id).then(setComments);
		}
	}, [id])

	const handleSubmit = () => {
		if (post)
			addComment(comment, post.id).then(comment => setComments([comment, ...comments]));
		setComment("");
	}

	const handleDeletedComment = (commentId: string) => {
		setComments(comments.filter((comment) => comment.id != commentId));
	}

	return (
		<>
			{post &&
				<Box className='body_grid'>
					<Post post={post} isExpanded={true} />
					<Box sx={{ display: "flex", marginLeft: "5%" }}>
						<TextField
							label="Ajouter un commentaire"
							value={comment}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => setComment(event.target.value)}
							variant="filled"
							onKeyDown={keyPress}
							inputProps={{ style: { fontSize: 15, color: "white", backgroundColor: "#525252" } }}
							InputLabelProps={{ style: { fontSize: 15, color: "white" } }}
							sx={{ width: "87%", padding: "0% 1%", backgroundColor: "#525252" }} />
						<Button
							sx={{ backgroundColor: "#6a6a6a", ":hover": { backgroundColor: "#707070", borderColor: "black" } }}
							type='submit'
							variant='contained'
							onClick={handleSubmit}
						>
							<SendIcon />
						</Button>
					</Box>
					<CommentList commentsList={comments} handleDeletedComment={handleDeletedComment} />
				</Box>
			}
		</>
	)
}