import { Button } from '@mui/material';
import { ErrorType } from '../../type/ErrorType';

type ButtonType = {
	label: string,
	setErrors: React.Dispatch<React.SetStateAction<ErrorType[]>>
}

export default function ButtonForm({ label, setErrors }: ButtonType) {
	return (
		<>
			<Button
				sx={{ color: 'black', ":hover": { borderColor: "black" }, borderColor: "darkgrey", float: "right" }}
				onClick={() => { setErrors([]) }}
				variant='outlined'
				type='submit'
			>
				{label}
			</Button>
		</>
	)
}