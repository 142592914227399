import { Typography } from '@mui/material';

type FormTitleProps = {
	label: string
}

export default function FormTitle({ label }: FormTitleProps) {
	return (
		<>
			<Typography variant='h4'
				style={{
					color: 'black',
					textAlign: 'center',
					marginBottom: '6%'
				}}
			>
				{label}
			</Typography>
		</>
	)
}