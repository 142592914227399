import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCommentsByUser } from '../../api/comment/GET';
import { getPostsByUser } from '../../api/post/GET';
import { getProfile } from '../../api/user/GET';
import Comment from '../../component/comment/Comment';
import Post from '../../component/post/Post';
import { CommentType } from '../../type/CommentType';
import { PostType } from '../../type/PostType';
import { ProfileType } from '../../type/Profile';

export default function ProfilePage() {
	const [profile, setProfile] = useState<ProfileType>();
	const [posts, setPosts] = useState<PostType[]>([]);
	const [comments, setComments] = useState<CommentType[]>([]);
	const { id } = useParams<string>();

	useEffect(() => {
		if (id) {
			getProfile(id).then(setProfile);
			getPostsByUser(id).then(setPosts);
			getCommentsByUser(id).then(setComments);
		}
	}, [])

	const handleDeletedComment = (commentId: string) => {
		setComments(comments.filter((comment) => comment.id != commentId));
	}

	return (
		<>
			{profile &&
				<Box className='body_grid'>
					<h1>{profile?.username}</h1>
					<Typography>Role: {profile.roles[0].name}</Typography>
					<Box sx={{ display: "flex", flexDirection: "row" }}>
						<Box sx={{ borderRadius: "4px", padding: "1%", width: "50%" }}>
							<h1>Posts</h1>
							<Box>
								{posts && posts.map(post => (<Post key={'post-' + post.id} post={post} isExpanded={false} />))}
							</Box>
						</Box>
						<Box sx={{ borderRadius: "4px", padding: "1%", width: "50%" }}>
							<h1>Commentaires</h1>
							<Box>
								{comments.map(comment => (
									<Comment key={comment.id} comment={comment} handleDeletedComment={() => handleDeletedComment(comment.id)} />
								))}
							</Box>
						</Box>
					</Box>
				</Box>
			}
		</>
	)
}