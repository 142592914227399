import { z } from 'zod';

export const RegistrationSchema = z.object({
	login: z.string()
		.min(3, { message: "Le nom d'utilisateur doit contenir au minimum 3 caractères" })
		.max(10, { message: "Le nom d'utilisateur doit contenir au maximum 10 caractères" }),
	password: z.string()
		.min(10, { message: "Le mot de passe doit contenir au minimum 10 caractères" }),
	confirmPassword: z.string()
}).superRefine(({ confirmPassword, password }, ctx) => {
	if (confirmPassword !== password) {
		ctx.addIssue({
			code: "custom",
			message: "Les mots de passe ne correspondent pas",
			path: ['confirmPassword']
		});
	};
});