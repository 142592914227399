import { Box } from '@mui/material';
import { Link } from "react-router-dom";
import ProfileMenu from './ProfileMenu';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import MessageIcon from '@mui/icons-material/Message';
import FavoriteIcon from '@mui/icons-material/Favorite';

export default function Header() {
	return (
		<Box id="header">
			<Link className='leftIconNavBar' to={"/"}><HomeIcon /></Link>
			<Link className='rightIconNavBar' to={"/post/add"}><AddIcon /></Link>
			<Link className='rightIconNavBar' to={"/messages"}><MessageIcon /></Link>
			<Link className='rightIconNavBar' to={"/liked"}><FavoriteIcon /></Link>
			<ProfileMenu />
		</Box>
	)
}