import { CardContent, TextField } from '@mui/material';

const inputStyle = { background: "#909090", color: "white" };

type PostFormContentProps = {
	content: string,
	setContent: React.Dispatch<React.SetStateAction<string>>
}

export default function PostFormContent({ content, setContent }: PostFormContentProps) {
	return (
		<>
			<CardContent sx={{ padding: "0 7%" }}>
				<TextField
					sx={{ fontSize: "1rem", width: "100%" }}
					inputProps={{ style: inputStyle }}
					placeholder='Contenu du post'
					multiline
					maxRows={50}
					value={content}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => setContent(event.target.value)}
				/>
			</CardContent>
		</>
	)
}