import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import PubBody from "./PubBody";
import { Box } from '@mui/material';

export default function Layout() {
	return (
		<Box id="layout">
			<Header />
			<Navbar />
			<Outlet />
			<PubBody />
			<Footer />
		</Box>
	)
}