// passer les postId en long et pas string, faut envoyer un object et pas juste une string
export const likePost = async (postId: string) => {
	const user = JSON.parse(localStorage.getItem('userInfo')!);
	return await fetch(process.env.REACT_APP_SERVER_URL + '/posts/like', {
		method: 'POST',
		mode: 'cors',
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${user?.token}`
		},
		credentials: 'same-origin',
		body: JSON.stringify({ "id": postId })
	})
		.then(response => response.json())
		.catch(response => console.error(response));
}

export const dislikePost = async (postId: string) => {
	const user = JSON.parse(localStorage.getItem('userInfo')!);
	return await fetch(process.env.REACT_APP_SERVER_URL + '/posts/dislike', {
		method: 'POST',
		mode: 'cors',
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${user?.token}`
		},
		credentials: 'same-origin',
		body: JSON.stringify({ "id": postId })
	})
		.then(response => response.json())
		.catch(response => console.error(response));
}

export const addPost = async (newPost: { title: string, content: string }) => {
	const user = JSON.parse(localStorage.getItem('userInfo')!);
	return await fetch(process.env.REACT_APP_SERVER_URL + '/posts', {
		method: 'POST',
		mode: 'cors',
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${user?.token}`
		},
		credentials: 'same-origin',
		body: JSON.stringify(newPost)
	})
		.then(response => response.json())
		.catch(response => console.error(response));
}