export const getPostComments = async (postId: string) => {
	const user = JSON.parse(localStorage.getItem('userInfo')!);
	return await fetch(process.env.REACT_APP_SERVER_URL + `/comments?` +
		new URLSearchParams({ postId: postId }).toString(), {
		method: 'GET',
		mode: 'cors',
		headers: { Authorization: `Bearer ${user?.token}` },
		credentials: 'same-origin',
	})
		.then(response => response.json())
		.catch(response => console.error(response))
}

export const getCommentsByUser = async (id: string) => {
	const user = JSON.parse(localStorage.getItem('userInfo')!);
	return await fetch(process.env.REACT_APP_SERVER_URL + `/comments?` +
		new URLSearchParams({ userId: id }).toString(), {
		method: 'GET',
		headers: { Authorization: `Bearer ${user?.token}` },
		mode: 'cors'
	})
		.then(response => response.json())
		.catch(response => console.error(response));
}