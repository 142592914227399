export const registration = async (LoginFormData: { login: string, password: string }) => {
	const response = await fetch(process.env.REACT_APP_SERVER_URL + '/registration', {
		method: 'POST',
		mode: 'cors',
		credentials: 'same-origin',
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(LoginFormData)
	});
	return {body: await response.text(), ok: response.ok};
}

