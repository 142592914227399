import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { getPosts } from '../../api/post/GET';
import PostList from '../../component/post/PostList';
import { PostType } from '../../type/PostType';

export default function HomePage() {
	const [posts, setPosts] = useState<PostType[]>([]);

	useEffect(() => {
		getPosts().then(setPosts);
	}, []);

	return (
		<>
			<Box className='body_grid'>
				<h1 className='body_grid_title'>Catégorie</h1>
				<Box>
					<PostList posts={posts} />
				</Box>
			</Box>
		</>
	);
}
