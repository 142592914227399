import { Box, Button, Card } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addPost } from '../../../api/post/POST';
import PostFormContent from './PostFormContent';
import PostFormHeader from './PostFormHeader';

export default function PostForm() {
	const [title, setTitle] = useState<string>("");
	const [content, setContent] = useState<string>("");
	const navigate = useNavigate();

	const handleSubmit = () => {
		addPost({ title, content }).then((post) => navigate("/post/" + post.id));
	}

	return (
		<Box>
			<Card id='post'>
				<PostFormHeader title={title} setTitle={setTitle} />
				<PostFormContent content={content} setContent={setContent} />
			</Card>
			<Button
				sx={{ float: "right", marginRight: "5%", backgroundColor: "#6a6a6a" }}
				variant='contained'
				onClick={() => handleSubmit()}
			>Créer</Button>
		</Box>
	)
}