import { Box } from '@mui/material';
import { CommentType } from '../../type/CommentType';
import Comment from './Comment';
import './comment.css';
import { useEffect, useState } from 'react';

type CommentListPropsType = {
	commentsList: CommentType[],
	handleDeletedComment: (commentId: string) => void
}

export default function CommentList({ commentsList, handleDeletedComment }: CommentListPropsType) {
	const [comments, setComments] = useState<CommentType[]>(commentsList);

	useEffect(() => {
		setComments(commentsList)
	}, [commentsList])

	return (
		<Box>
			{comments.map(comment => (
				<Comment comment={comment} handleDeletedComment={() => handleDeletedComment(comment.id)} />
			))}
		</Box>
	)
}