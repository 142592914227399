import { jwtDecode } from 'jwt-decode';
import { useContext } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Layout from "./component/Layout";
import Logout from './component/Logout';
import HomePage from './page/homepage/HomePage';
import LikedPage from './page/liked/LikedPage';
import LoginPage from './page/login/LoginPage';
import PostPage from './page/post/PostPage';
import ProfilePage from './page/profile/ProfilePage';
import RegistrationPage from './page/registration/RegistrationPage';
import { UserContext } from './setting/AuthProvider';
import PostFormPage from './page/post/form/PostFormPage';

const RoleAccess = ({ roles, routeType }: { roles: string[], routeType: string }) => {
	const { user } = useContext(UserContext);
	if (routeType === "login" || routeType === "unauthorized") {
		if (IsTokenValid()) {
			return <Navigate to="/" replace />;
		} else {
			return <Outlet />
		}
	} else {
		if (IsTokenValid()) {
			if (user && roles.includes(user.role)) {
				return <Outlet />;
			} else {
				return <Navigate to="/unauthorized" replace />;
			}
		} else {
			return <Navigate to="/login" replace />;
		}
	}
};

const IsTokenValid = () => {
	const { user } = useContext(UserContext);
	if (user && user.token !== "" && user.token !== null) {
		const decodedToken = jwtDecode(user.token);
		if (decodedToken.exp) {
			const tokenExpToDate = new Date(decodedToken.exp * 1000);
			return tokenExpToDate > new Date();
		}
	}
	return false;
}

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route element={<RoleAccess roles={["ROLE_USER", "ROLE_MODO", "ROLE_ADMIN"]} routeType={""} />} >
						<Route index element={<HomePage />} />
						<Route path='/messages' element={<p className='body_grid'>Messagerie privée</p>} />
						<Route path='/liked' element={<LikedPage />} />
						<Route path='/profile/:id' element={<ProfilePage />} />
						<Route path='/post/:id' element={<PostPage />} />
						<Route path='/post/add' element={<PostFormPage />} />
						<Route path='/logout' element={<Logout />} />
					</ Route>
				</Route>
				<Route element={<RoleAccess roles={[""]} routeType={"login"} />} >
					<Route path='/login' element={<LoginPage />} />
				</Route>
				<Route path='/registration' element={<RegistrationPage />} />
				<Route element={<RoleAccess roles={[""]} routeType={"unauthorized"} />} >
					<Route path='/unauthorized' element={<p>Pas le droit d'accéder à ces ressources</p>} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
