import {
	Box,
	Card
} from '@mui/material';
import { PostPropsType } from '../../type/PostType';
import './post.css';
import PostActions from './PostActions';
import PostContent from './PostContent';
import PostContentPreview from './PostContentPreview';
import PostHeader from './PostHeader';

export default function Post({ post, isExpanded }: PostPropsType) {
	return (
		<Box>
			<Card key={post.id} id='post'>
				<PostHeader post={post} />
				{isExpanded ? <PostContent post={post} /> : <PostContentPreview post={post} />}
				<PostActions post={post} isExpanded={isExpanded} />
			</Card>
		</Box>
	);
};
