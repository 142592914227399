import CommentIcon from '@mui/icons-material/Comment';
import DeleteIcon from '@mui/icons-material/Delete';
import LikeIcon from '@mui/icons-material/Favorite';
import { CardActions, IconButton } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dislikePost, likePost } from '../../api/post/POST';
import { UserContext } from '../../setting/AuthProvider';
import { PostType } from '../../type/PostType';
import { deletePost } from '../../api/post/DELETE';

type PostActionsProps = {
	post: PostType,
	isExpanded: boolean
}

export default function PostActions({ post, isExpanded }: PostActionsProps) {
	const { user } = useContext(UserContext);
	const [isPostLiked, setIsPostLiked] = useState<boolean>(post.isLiked);
	const navigate = useNavigate();

	return (
		<CardActions disableSpacing>
			{!(post.author.username === user?.username) &&
				<IconButton
					aria-label='add to favorites'
					onClick={() => {
						if (isPostLiked) {
							dislikePost(post.id).then(() => setIsPostLiked(false));
						} else {
							likePost(post.id).then(() => setIsPostLiked(true));
						}
					}}
				>
					<LikeIcon sx={isPostLiked ? { color: 'red' } : { color: 'white' }} />
				</IconButton>}
			{!isExpanded && <IconButton aria-label='comment' onClick={() => navigate("/post/" + post.id)}>
				<CommentIcon sx={{ color: "white" }} />
			</IconButton>}
			{(user?.role === "ROLE_ADMIN" || post.author.username === user?.username) &&
				<IconButton sx={{ marginRight: "0", marginLeft: "auto" }} onClick={() => deletePost(post.id)}>
					<DeleteIcon sx={{ color: "white" }} />
				</IconButton>}
		</CardActions>
	)
}