import { Avatar as MuiAvatar, Tooltip } from '@mui/material';
import { brown } from '@mui/material/colors';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../setting/AuthProvider';

type AvatarPropsType = {
	link?: string,
	username?: string,
}
export default function Avatar({ link, username }: AvatarPropsType) {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	return (
		<Tooltip title={username} arrow slotProps={{
			popper: {
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, -9],
						},
					},
				],
			},
		}}>
			<MuiAvatar
				id='avatarTest'
				sx={{ bgcolor: brown[500] }}
				onClick={() => link && navigate(link)}
			>
				{(username ? username : user!.username).charAt(0).toUpperCase()}
			</MuiAvatar>
		</Tooltip>
	)
}