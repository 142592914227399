import DeleteIcon from '@mui/icons-material/Delete';
import { Card, CardActions, IconButton } from '@mui/material';
import { useContext } from 'react';
import { deleteComment } from '../../api/comment/POST';
import { UserContext } from '../../setting/AuthProvider';
import { CommentType } from '../../type/CommentType';
import CommentContent from './CommentContent';
import CommentHeader from './CommentHeader';
;


type CommentPropsType = {
	comment: CommentType,
	handleDeletedComment: (commentId: string) => void
}

export default function Comment({ comment, handleDeletedComment }: CommentPropsType) {
	const { user } = useContext(UserContext);

	const handleDelete = (commentId: string) => {
		deleteComment(commentId).then(() => handleDeletedComment(commentId));
	}

	return (
		<Card key={comment.id} id='comment'>
			<CommentHeader comment={comment} />
			<CommentContent comment={comment} />
			<CardActions>
				<IconButton sx={{ marginRight: "0", marginLeft: "auto" }} onClick={() => handleDelete(comment.id)}>
					{comment.author.id === user?.id && <DeleteIcon sx={{ color: "white" }} />}
				</IconButton>
			</CardActions>
		</Card>
	)
}